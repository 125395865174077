import { Box, Button, LinearProgress } from "@mui/material";
import { Close, Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { checkFile, isMobileApp, saveFile, sendDataToMobileApp } from "./Utilities";

const Progress = (props) => (
    <Box
        sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            overflow: "hidden",
        }}
    >
        <LinearProgress variant="determinate" value={props.value} />
    </Box>
)

export default function DownloadButton(props) {
    
    const { sourceUrl, targetFilename, disabled: disabledByParent, type } = props;
    const { t } = useTranslation();
    
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(disabledByParent);

    const ref = useRef();
    
    useEffect(() => {
        const checkFileAsync = async() => {
            const { exists, contentType } = await checkFile(sourceUrl)

            if (exists && contentType === type) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }
    
        if (!disabledByParent) {
            checkFileAsync()
        }
    }, [sourceUrl, disabledByParent]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = async() => {
        try {
            if (isMobileApp) {
                sendDataToMobileApp({
                    type: "download",
                    sourceUrl,
                    targetFilename
                })
                return
            }

            setLoading(true)
            setProgress(0)

            const xhr = new XMLHttpRequest()
            ref.current = xhr // set created xhr object to ref to be able to cancel later

            xhr.open("GET", sourceUrl, true)
            xhr.responseType = "blob"

            const progressBuffer = []; // array for the moving average
            const maxBufferSize = 5; // how many steps are to be averaged

            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;

                    // add to the buffer and shorten if necessary
                    progressBuffer.push(percentComplete);
                    if (progressBuffer.length > maxBufferSize) {
                        progressBuffer.shift(); // remove oldest entries
                    }

                    // calculate average
                    const averageProgress = progressBuffer.reduce((a, b) => a + b, 0) / progressBuffer.length;

                    setProgress(averageProgress);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    saveFile(xhr.response, targetFilename)
                    setLoading(false)
                }
            }

            xhr.onerror = (event) => {
                console.error(event)
                setLoading(false)
            }

            xhr.send()
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const handleCancel = () => {
        if (ref.current) {
            ref.current.abort();
        }
        setLoading(false);
    }

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",
        }}>
            
            <Box sx={{ position: "relative", width: "100%" }}>
                <LoadingButton
                    sx={{ width: "100%", transition: "all 0.3s ease-in-out" }}
                    variant="contained"
                    size="large"
                    startIcon={<Download />}
                    onClick={handleDownload}
                    disabled={disabled}
                    loading={loading}
                    loadingIndicator={
                        <p style={{
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>
                            {loading ? t("actions.saving") : t("actions.loading")}...
                        </p>
                    }
                >
                    {t("actions.download")}
                </LoadingButton>

                {loading && (
                    <Progress value={progress || 0} />
                )}

            </Box>

            {loading && (
                <Button
                    sx={{ flexShrink: 0 }}
                    variant="outlined"
                    size="large"
                    onClick={handleCancel}
                >
                    <Close />
                </Button>
            )}

        </Box>
    )
}