import { Box, IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ArrowBackIosNew } from "@mui/icons-material"
import { isIframe, sendDataToIframe, useCheckSingleProject } from "../Utilities"

export default function Logo({ src, invertColors }) {
    
    const { LOGO_FRAME } = window.conf.LAYOUT
    const { t } = useTranslation()
    const isSingleProject = useCheckSingleProject()

    if (isIframe && !isSingleProject) {
        return (
            <Tooltip 
                title={t("actions.back")}
                arrow
                placement="bottom-start"
            >
                <IconButton
                    sx={{ 
                        p: 0,
                        color: invertColors ? 'white' : 'primary.main'
                    }}
                    onClick={() => sendDataToIframe("goBack")}
                >
                    <ArrowBackIosNew 
                        sx={{ 
                            fontSize: "1.2rem", 
                            pb: "1px"
                        }}
                    />
                </IconButton>
            </Tooltip>
        )
    } else {
        return (
            <Box 
                sx={{ 
                    flexGrow: 0, 
                    display: src ? { xs: 'none', sm: 'flex' } : "none"
                }}
            >
                <div style={{ 
                    backgroundColor: src && LOGO_FRAME ? 'white' : 'transparent', 
                    padding: 4,
                    display: 'flex'
                }}> 
                    <img
                        src={src}
                        alt='logo'
                        height='32px'
                    />
                </div>
            </Box>
        )
    }
}